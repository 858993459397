import React from 'react';
import ReactDOM from 'react-dom';
import { appMonitor, MatchPathFn } from '@domaintools-frontend/utils';

import { getServerUserData } from '../StateManagement';

export const renderApp = (
  onImport: () => Promise<{ default: React.ComponentType<any> }>,
  monitor: boolean = true,
  matchPath: MatchPathFn = () => null
) => {
  // Prevent the app from executing if html file is accessed directly.
  if (!window.location.pathname.match(/index\.html$/)) {
    if (monitor) {
      appMonitor.init(matchPath);
      const userData = getServerUserData();
      if (userData) {
        appMonitor.setUser(String(userData.accountId));
      }
    }

    onImport().then((module: { default: React.ComponentType<any> }) => {
      const App = module.default;
      ReactDOM.render(<App />, document.getElementById('root'));
    });
  }
};

export default renderApp;
