import { StoreEnhancer } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AxiosError } from 'axios';
import _ from 'lodash';

import { MatchPathFn } from './createMatchPath';
import { getCurrentTLD } from './getCurrentTLD';

// Experiment: ChunkLoadErrors may be caused by a new deployment. Attempt to clear the error with a page refresh.
window.addEventListener('unhandledrejection', (event) => {
  const failedToLoadChunk =
    _.isError(event.reason) &&
    event.reason.message.match(/loading.*chunk.*failed/i);
  if (failedToLoadChunk) {
    event.preventDefault();

    // Prevent redirect loop.
    const cacheKey = `'chunkErrorPageReloaded_${process.env.REACT_APP_GIT_COMMITHASH}`;
    if (!localStorage.getItem(cacheKey)) {
      localStorage.setItem(cacheKey, 'true');
      window.location.reload();
    }
  }
});

const tracingAllowList = ['Detect'];

const sentryMonitor = {
  init(matchPath: MatchPathFn = () => null) {
    const reduxCaptureStateDepth = 10;

    const integrations = [];
    if (tracingAllowList.includes(process.env.REACT_APP_APP_NAME ?? '')) {
      integrations.push(
        new BrowserTracing({
          tracingOrigins: [`api.domaintools.${getCurrentTLD()}`],
          beforeNavigate(context) {
            const match = matchPath(window.location.pathname);
            if (!match) {
              return context;
            }

            // Group navigation transactions by path.
            return {
              ...context,
              name: match.path,
            };
          },
        })
      );
    }

    Sentry.init({
      dsn: 'https://8c39b53837ba4b03a2238fbe7502712c@o1227180.ingest.sentry.io/6375472',
      environment: process.env.REACT_APP_APP_NAME,
      release: process.env.REACT_APP_GIT_VERSION,
      normalizeDepth: reduxCaptureStateDepth,
      tracesSampleRate: 1.0, // @todo - Adjust this down if too many transactions are being captured.
      integrations,
      ignoreErrors: [
        // Likely issue with form based browser extensions.
        // @see: https://github.com/getsentry/sentry-javascript/issues/3440
        'Non-Error promise rejection captured',
        // @see: https://stackoverflow.com/questions/69047420/webpack-code-splitting-chunkloaderror-loading-chunk-x-failed-but-the-chunk-e
        /loading.*chunk.*failed/i,
        // Network error occurred for fetch API. Unfortunately, no information on what error (CORS, no response, lost connection) is provided.
        /failed to fetch/i,
      ],
      beforeSend(event, hint) {
        const error = hint?.originalException as AxiosError;
        if (_.isError(error) && error.isAxiosError) {
          const status = error.response?.status ?? 0;
          // We don't want to capture user errors.
          return status > 399 && status < 400 ? null : error;
        }

        return error;
      },
    });
  },
  setUser(accountId: string) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id: accountId });
    });
  },
  capture(error: any) {
    Sentry.captureException(error);
  },
  applyEnhancer(enhancers: StoreEnhancer[]) {
    enhancers.push(Sentry.createReduxEnhancer());
  },
};

const stubMonitor = {
  init() {},
  setUser() {},
  capture(error: any) {
    console.error(error);
  },
  applyEnhancer() {},
};

export const appMonitor =
  process.env.NODE_ENV === 'production' &&
  window.location.origin.split('.').pop() === 'com'
    ? sentryMonitor
    : stubMonitor;

export default appMonitor;
